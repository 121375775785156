import Vue from "vue/dist/vue.esm"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      component: () => import("../components/app.vue"),
      children: [
        {
          path: "",
          name: "start",
          props: true,
          component: () => import("../components/start.vue")
        },
        {
          path: "/register",
          name: "register",
          props: true,
          component: () => import("../components/register.vue")
        },
        {
          path: "/question/:id",
          name: "question",
          props: true,
          component: () => import("../components/question.vue")
        },
        {
          path: "/results",
          name: "results",
          props: true,
          component: () => import("../components/results.vue")
        },
        {
          path: "/secret",
          name: "secret",
          props: true,
          component: () => import("../components/secret/secret.vue"),
          children: [
            {
              path: "",
              name: "secretStart",
              props: true,
              component: () => import("../components/secret/start.vue")
            },
            {
              path: "questions",
              name: "secretQuestions",
              props: true,
              component: () => import("../components/secret/questions.vue")
            },
            {
              path: "questions/new",
              name: "secretQuestionsNew",
              props: true,
              component: () => import("../components/secret/questions-new.vue")
            },
            {
              path: "questions/edit/:id",
              name: "secretQuestionsEdit",
              props: true,
              component: () => import("../components/secret/questions-edit.vue")
            }
          ]
        },
        {
          path: "secret/questions/qrcodes",
          name: "secretQuestionsQrCodes",
          props: true,
          component: () => import("../components/secret/questions-qr-codes.vue")
        }
      ]
    }
  ]
})

export default router
