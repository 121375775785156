import Vue from "vue/dist/vue.esm"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

import user from "./user.js"

export default new Vuex.Store({
  modules: {
    user: user
  },
  plugins: [createPersistedState({ paths: ["user.currentUser"] })]
})
