import Vue from "vue/dist/vue.esm"
import { firestorePlugin } from 'vuefire'

Vue.use(firestorePlugin)

import Firebase from "firebase"
import firestore from "firebase/firestore"

var config = {
  apiKey: "AIzaSyBKKhjf0SEj9Kk2Ekttk-hLT_L-AOVcZAA",
  authDomain: "qrpromenad.firebaseapp.com",
  databaseURL: "https://qrpromenad.firebaseio.com",
  projectId: "qrpromenad",
  storageBucket: "qrpromenad.appspot.com",
  messagingSenderId: "625927811799"
}

const firebase = Firebase.initializeApp(config)

export default firebase.firestore()
