export default {
  namespaced: true,
  state: {
    currentUser: null
  },
  mutations: {
    setCurrentUser(state, parameters) {
      state.currentUser = parameters.user
    }
  },
  actions: {}
}
