import "@babel/polyfill"
import "./css/screen.scss"

import Vue from "vue"
import Firebase from "./initializers/firebase.js"
import VeeValidate from "./initializers/vee-validate.js"
import fontAwesome from "./initializers/font-awesome.js"
import router from "./initializers/routes.js"
import store from "./stores/application.js"

window.eventHub = new Vue()

document.addEventListener("DOMContentLoaded", () => {
  document.body.appendChild(document.createElement("app"))
  
  const app = new Vue({
    el: "app",
    router,
    store,
    template: `<router-view></router-view>`
  })
})
